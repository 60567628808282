import axios from "axios";
import "@/config";
import store from "@/store";
import Qs from "qs";
import { setupCache } from "axios-cache-adapter";
import { showResponse } from "~/notifications";
import Vue from "vue";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  limit: 50,
  clearOnStale: true,
  readOnError: true,
  exclude: {
    query: false,
    // paths: ["^/(?!ebsn/api/products)(.*)", "^/(?!ebsn/api/category)(.*)"]
    filter: function(path) {
      let include =
        (path.url.indexOf("/ebsn/api/products") === 0 &&
          !path.params["parent_wishlist_id"] &&
          path.params["preferred"] != true) ||
        path.url.indexOf("/ebsn/api/category") === 0 ||
        path.url.indexOf("/ebsn/api/adv") === 0 ||
        path.url.indexOf("/ebsn/api/warehouse-locator") === 0 ||
        path.url.indexOf("/ebsn/api/article") === 0 ||
        path.url.indexOf("/p/wp-json/wp/v2/") === 0;
      return !include;
    }
  },
  invalidate: async (cfg, req) => {
    const method = req.method.toLowerCase();
    if (method !== "get") {
      await cfg.store.removeItem(cfg.uuid);
    }
  }
});

const axiosInstance = axios.create({
  // baseURL: process.env.VUE_APP_EBSN_URL,
  paramsSerializer: function(params) {
    return Qs.stringify(params, { arrayFormat: "repeat" });
  },
  adapter: cache.adapter
});

axiosInstance.options.crossOrigin = true;

function toggleProgressLoader(show) {
  store.commit(
    show ? "app/increasePendingRequests" : "app/decreasePendingRequests"
  );
}

async function getUrlWithRecaptchaToken(url) {
  if (window.grecaptcha.enterprise != undefined) {
    //recaptcha enterprise implementation
    return new Promise(resolve => {
      window.grecaptcha.enterprise.ready(async () => {
        const action = url.replace(/-/g, "_");
        const token = await window.grecaptcha.enterprise.execute(
          process.env.VUE_APP_RECAPTCHA_SITE_KEY,
          {
            action: action
          }
        );
        if (/[?&]/.test(url)) {
          resolve(url.concat(`&token_recaptcha=${token}`));
        }
        resolve(`${url}?token_recaptcha=${token}`);
      });
    });
  } else {
    //base recatocha implementation
    const action = url.replace(/-/g, "_");
    const token = await global.vm.$recaptcha(action);
    if (/[?&]/.test(url)) {
      return url.concat(`&token_recaptcha=${token}`);
    }
    return `${url}?token_recaptcha=${token}`;
  }
  // IMPORTANT: The 'token' that results from execute is an encrypted response sent by
  // reCAPTCHA Enterprise to the end user's browser.
  // This token must be validated by creating an assessment.
  // See https://cloud.google.com/recaptcha/docs/create-assessment
  // const action = url.replace(/-/g, "_").substring(url.lastIndexOf("/") + 1);
}

axiosInstance.interceptors.request.use(async request => {
  if (
    (!request.baseURL && request.url.startsWith("/ebsn/")) ||
    (request.baseURL &&
      request.baseURL.indexOf(process.env.VUE_APP_EBSN_URL) >= 0) ||
    request.url.indexOf("localhost") > 0
  ) {
    request.headers["Accept"] = "application/json";
    const session = window.sessionStorage.getItem("X-Ebsn-Session");
    if (session) {
      request.headers["X-Ebsn-Session"] = session;
    }
    const account = window.sessionStorage.getItem("X-Ebsn-Account");
    if (account) {
      request.headers["X-Ebsn-Account"] = account;
    }
    request.headers["X-Ebsn-Client"] = process.env.VUE_APP_CLIENT;
    request.headers["X-Ebsn-Client-Redirect"] = process.env.VUE_APP_CLIENT;
    request.headers["X-Ebsn-Version"] = process.env.VUE_APP_VERSION;
    if (
      process.env.VUE_APP_MULTILANGUAGE == "true" &&
      store.state.app.language
    ) {
      request.headers["X-Ebsn-Lang"] = store.state.app.language;
    }
    if (store.state.app.cookiePreferences.uuid) {
      request.headers["X-Ebsn-Client-UUID"] =
        store.state.app.cookiePreferences.uuid;
    }
  }
  if (
    global.config.recaptchaTokenEnabled &&
    global.config.recaptchaEnabledEndpoints
  ) {
    if (
      global.config.recaptchaEnabledEndpoints.includes(request.url) &&
      (!request.data ||
        typeof request.data.get !== "function" ||
        !request.data.get("mfa_pin"))
    ) {
      request.url = await getUrlWithRecaptchaToken(request.url);
    }
  }
  toggleProgressLoader(true);
  return request;
});

axiosInstance.interceptors.response.use(
  response => {
    toggleProgressLoader(false);
    let account =
      response.headers["X-Ebsn-Account"] || Vue.$cookies?.get("X-Ebsn-Account");
    if (account) {
      window.localStorage.setItem("X-Ebsn-Account", account);
    } else {
      window.localStorage.removeItem("X-Ebsn-Account");
    }
    let session = response.headers["X-Ebsn-Session"];
    if (session) {
      window.sessionStorage.setItem("X-Ebsn-Session", session);
    } else {
      window.sessionStorage.removeItem("X-Ebsn-Session");
    }
    if (response.data && response.data.user) {
      if (response.data.user.userId > -1) {
        window.sessionStorage.setItem("is-authenticated", true);
      } else {
        window.sessionStorage.removeItem("is-authenticated");
      }
    }

    if (response && response.data && response.data.response) {
      //check if authenticated
      //check per caso specifico delete address
      if (response.config.url.indexOf("/ebsn/api/user-address/delete") > -1) {
        if (
          response.data.response.infosMessage.length > 0 &&
          response.data.response.infos.length > 0
        ) {
          if (
            response.data.response.infosMessage[0].code
              .toLowerCase()
              .indexOf("error") > -1
          ) {
            response.data.response.infos[0].info =
              "Non è possibile eliminare l'indirizzo selezionato per la consegna della spesa.";
            showResponse(response.data.response.infos, "error", "info");
            response.data.response.infos = [];
            response.data.response.infosMessage = [];
          }
        } else {
          if (
            response.data.response.errorsMessage.length > 0 &&
            response.data.response.errors.length > 0
          ) {
            response.data.response.errors[0].error =
              "Non è possibile eliminare l'indirizzo selezionato per la consegna della spesa.";
          }
        }
      }

      if (global.config?.customWishlistDelete) {
        if (
          response.config.url.indexOf("/ebsn/api/wishlist/delete_prods") > -1
        ) {
          response.data.response.infos = null;
          response.data.response.infosMessage = null;
        }
      }

      if (
        response.data.response.infosMessage &&
        response.data.response.infosMessage.length > 0
      )
        showResponse(response.data.response.infosMessage, "success");
      if (
        response.data.response.errorsMessage &&
        response.data.response.errorsMessage.length > 0
      )
        showResponse(response.data.response.errorsMessage, "error");
      if (
        response.data.response.warningsMessage &&
        response.data.response.warningsMessage.length > 0
      )
        showResponse(response.data.response.warningsMessage, "warning");
    }

    if (response && response.data && response.data.ssoUser) {
      store.commit("cart/SET_SSOUSER", response.data.ssoUser);
    }

    return response;
  },
  error => {
    toggleProgressLoader(false);

    if (error.response && 419 === error.response.status) {
      //its not an error
      //showResponse(error.response.data.response.infos, "success", "info");
      global.EventBus.$emit("isNotLoggedIn");
      showResponse(
        "La sessione è scaduta, si prega di rieffettuare il login per continuare.",
        "error"
      );
      window.sessionStorage.removeItem("is-authenticated");
      window.sessionStorage.removeItem("X-Ebsn-Session");
      window.localStorage.removeItem("X-Ebsn-Account");
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.response
    ) {
      if (error.response.data.response.infosMessage.length > 0)
        showResponse(error.response.data.response.infosMessage, "success");
      if (error.response.data.response.errorsMessage.length > 0)
        showResponse(error.response.data.response.errorsMessage, "error");
      if (error.response.data.response.warningsMessage.length > 0)
        showResponse(error.response.data.response.warningsMessage, "warning");
    }

    if (error.response && error.response.data && error.response.data.response) {
      if (error.response.data.response.errors.length == 0) {
        error.response.data.response.errors.push({
          error: "Si è verificato un errore, riprova tra qualche minuto."
        });
      }
      return Promise.reject(error.response.data.response);
    } else {
      return Promise.reject({
        status: 1,
        errors: [
          {
            error: "Si è verificato un errore, riprova tra qualche minuto."
          }
        ]
      });
    }
  }
);

export default axiosInstance;
