<template>
  <div
    class="cart-info-card-wrapper d-flex d-md-none align-center grey lighten-1 rounded-md py-3"
    v-if="isAuthenticated"
  >
    <v-btn
      :aria-label="serviceName"
      text
      @click="openAddressSelector"
      class="service-button"
      height="50"
    >
      <v-icon large class="service-icon">
        {{ "$" + $t(`navbar.service.${getDeliveryServiceId}.fontIcon`) }}
      </v-icon>
    </v-btn>
    <div
      class="cart-info-address-card-wrapped nav-selectors d-flex flex-column"
      v-if="!isFast"
    >
      <v-btn
        x-small
        text
        class="delivery-btn d-flex justify-space-between"
        @click="openAddressSelector"
      >
        <span class="info-text font-weight-bold">{{ shippingAddress }}</span
        ><v-icon color="default" x-small class="ml-2">$edit</v-icon>
      </v-btn>
      <v-btn
        x-small
        text
        class="delivery-btn d-flex justify-space-between"
        @click="openTimeslotSelector"
      >
        <span class="info-text font-weight-regular text-lowercase">
          {{ timeslot }} </span
        ><v-icon color="default" x-small class="ml-2">$edit</v-icon>
      </v-btn>
    </div>
    <div
      class="cart-info-address-card-wrapped nav-selectors d-flex flex-column"
      v-else
    >
      <v-btn
        x-small
        text
        class="delivery-btn d-flex justify-space-between"
        @click="openAddressSelector"
      >
        <span class="info-text font-weight-bold">{{ shippingAddress }}</span
        ><v-icon color="default" x-small class="ml-2">$edit</v-icon>
      </v-btn>
      <v-btn
        x-small
        text
        class="delivery-btn d-flex justify-space-between"
        @click="openTimeslotSelector"
      >
        <span class="info-text font-weight-regular text-lowercase">
          {{ isFast ? timeslot.split("-")[0] : timeslot }} </span
        ><v-icon color="default" x-small class="ml-2">$edit</v-icon>
      </v-btn>
      <div
        class="d-flex mt-1 mt-md-0 ml-1 ml-md-0"
        v-if="isFast"
        :key="componenyKey"
      >
        <v-btn
          color="primary"
          v-if="isLoading"
          x-small
          class="elevation-0"
          text
          icon
          :loading="true"
        >
        </v-btn>
        <span
          class="expiry-date rounded-sm white--text"
          v-else-if="minutesToFastTimeslot > 0"
        >
          <p class="px-4 mb-0 text-uppercase">
            <strong style="font-size: 12px"
              >Scade tra {{ minutesToFastTimeslot }} min</strong
            >
          </p>
        </span>
        <strong v-else class="px-4 expiry-date-expired rounded-sm white--text"
          >SCADUTA</strong
        >
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.cart-info-card-wrapper {
  .expiry-date {
    background-color: #d69333;
  }
  .expiry-date-expired {
    background-color: var(--v-primary-darken1);
  }
  .nav-selectors,
  .delivery-btn .v-btn__content {
    width: 100%;
    overflow: hidden;
  }
  .info-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
<script>
import cartInfo from "~/mixins/cartInfo";
import login from "~/mixins/login";

export default {
  name: "CartInfoCardWrapper",
  mixins: [cartInfo, login],
  data() {
    return {
      minutesToFastTimeslot: 0,
      componenyKey: 0,
      isLoading: true
    };
  },
  computed: {
    isFast() {
      const fastIds = [13, 14, 15, 16, 17, 18];
      return fastIds.includes(this.cart.shippingAddress.deliveryServiceId);
    }
  },
  methods: {
    startFastTimeslotCountdown() {
      let _this = this;
      let interval = null;
      const updateCountdown = () => {
        let date1 =
          _this.cart.timeslot.cutoffTime + " " + _this.cart.timeslot.date;
        date1 = _this.$dayjs(date1);

        _this.minutesToFastTimeslot = date1.diff(
          _this.$dayjs(),
          "minute",
          true
        );
        //_this.minutesToFastTimeslot = Math.abs(_this.minutesToFastTimeslot);
        if (_this.minutesToFastTimeslot <= 0) {
          _this.minutesToFastTimeslot = 0;
          clearInterval(interval);
        } else {
          _this.minutesToFastTimeslot = Math.floor(_this.minutesToFastTimeslot);
        }
        if (_this.isLoading) {
          _this.isLoading = false;
        }
        console.log(_this.minutesToFastTimeslot);
      };
      updateCountdown();
      // update after every 1 mins
      if (_this.minutesToFastTimeslot > 0) {
        interval = setInterval(updateCountdown, 60000);
      }
    }
  },
  created() {
    global.EventBus.$on("updateExpiryDate", () => {
      this.startFastTimeslotCountdown();
      this.componenyKey++;
    });
  },
  mounted() {
    if (this.isFast) this.startFastTimeslotCountdown();
  }
};
</script>
