var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{key:_vm.componentKey,ref:"productCard",staticClass:"product-card",class:[_vm.promoClass],attrs:{"outlined":"","id":`product-card-${_vm.product.slug}`,"to":{ name: 'Product', params: { slug: _vm.product.slug } }}},[_c('div',{staticClass:"product",on:{"click":function($event){return _vm.$emit('selectProduct')}}},[_c('div',{staticClass:"d-flex justify-end align-center w-100 top"},[_c('div',{staticClass:"d-flex"},[(_vm.vendor)?_c('img',{attrs:{"width":"36","height":"36","src":_vm.vendor.iconSource,"alt":`Immagine marchio ${_vm.vendor.name}`}}):_vm._e(),_c('v-tooltip',{key:_vm.heartKey,attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","aria-label":"Aggiungi ad una lista"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.addToFavorites.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('transition',{attrs:{"name":"pulse"}},[_c('v-icon',{key:_vm.highlight,attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.highlight ? "$heartfull" : "$heart")+" ")])],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("product.addToList")))])])],1)]),_c('ProductAcqLimit',{attrs:{"product":_vm.product}}),_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"d-flex flex-column body"},[_c('img',{staticClass:"product-img align-self-center",attrs:{"width":"170","height":"170","src":_vm.product.mediaURL,"onerror":"this.onerror=null;this.src='/no-icon.png'","alt":'Immagine' + _vm.product.name,"title":_vm.product.name + ' (' + _vm.product.codInt + '-' + _vm.product.codVar + ')'}}),(
            _vm.product.warehousePromo &&
              _vm.product.warehousePromo.view &&
              _vm.product.warehousePromo.view.bubble
          )?_c('div',{staticClass:"promo_bubble",domProps:{"innerHTML":_vm._s(_vm.product.warehousePromo.view.bubble)}}):_vm._e(),_c('div',{staticClass:"description default--text d-flex flex-column justify-left w-100 mt-3 ml-1 ml-sm-0"},[_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.product.name)+" ")]),_c('div',{},[_c('span',{staticClass:"short_descr"},[_vm._v(" "+_vm._s(_vm.product.shortDescr)+" ")]),_c('div',{staticClass:"d-flex align-center"},[(_vm.product.description)?_c('span',{staticClass:"descr"},[_vm._v(" "+_vm._s(_vm.product.description))]):_vm._e(),(
                  _vm.product.productInfos?.TIPOLOGIA != 'Sfuso' &&
                    _vm.product.priceUmDisplay
                )?_c('span',{staticClass:"weight-unit d-flex"},[(
                    _vm.product.priceStandardUmDisplay &&
                      _vm.product.priceStandardUmDisplay != _vm.product.priceUmDisplay
                  )?_c('div',{staticClass:"mr-1"},[_c('div',{staticStyle:{"text-decoration":"line-through"}},[_vm._v(" "+_vm._s(_vm.$n(_vm.product.priceStandardUmDisplay, "currency"))+"/"+_vm._s(_vm.product.weightUnitDisplay)+" ")])]):_vm._e(),_vm._v(" "+_vm._s(_vm.$n(_vm.product.priceUmDisplay, "currency"))+"/"+_vm._s(_vm.product.weightUnitDisplay)+" ")]):(_vm.product.productInfos?.TIPOLOGIA == 'Sfuso')?_c('span',{staticClass:"weight-unit descr"},[_vm._v(" "+_vm._s(_vm.minQuantity)+" "+_vm._s(_vm.unit)+" ")]):_vm._e(),(
                  _vm.quantity > 0 || (_vm.selectedOptions && _vm.selectedOptions.name)
                )?_c('SelectedOptionsMenu',{attrs:{"product":_vm.product,"selectOptionsArray":_vm.selectOptionsArray,"selectedOptionSelected":_vm.selectedOptions},on:{"onOptionChanged":_vm.onOptionChanged,"setSelectOptions":_vm.setSelectOptions}}):_vm._e()],1)]),_c('span',{staticClass:"product-classes-wrapper"},_vm._l((_vm.promoProductClasses),function(pClass){return _c('ProductClass',{key:pClass.productClassid,attrs:{"pClass":pClass}})}),1)])])])],1),_c('div',{staticClass:"actions",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('div',{staticClass:"promo-wrapper-product-card",attrs:{"id":`promo-wrapper-${_vm.product.productId}`}},[(_vm.product.newProduct)?_c('v-chip',{staticClass:"new text-uppercase black--text",attrs:{"color":"accent"}},[_vm._v(" "+_vm._s(_vm.$t("filter.new"))+" ")]):_vm._e(),(_vm.product.warehousePromo)?_c('ProductPromo',{attrs:{"warehousePromo":_vm.product.warehousePromo}}):(
          _vm.product.userGiftCertificates &&
            _vm.product.userGiftCertificates.length > 0
        )?_vm._l((_vm.product.userGiftCertificates),function(userGiftCertificate){return _c('product-gift-certificate',{key:userGiftCertificate.giftCertificate.giftCertificateId,attrs:{"giftCertificate":userGiftCertificate.giftCertificate,"containerRef":_vm.$refs.productCard,"wrapperId":`promo-wrapper-${_vm.product.productId}`},on:{"add":_vm.giftAdded}})}):_vm._e()],2),(_vm.product.priceDisplay)?_c('ProductPrice',{attrs:{"product":_vm.product}}):_vm._e(),_c('v-spacer'),(_vm.product.dayLock)?_c('DayLock',{attrs:{"days":_vm.product.dayLock,"showTooltip":true}}):_vm._e(),(_vm.product.productInfos && _vm.product.productInfos.LEAD_TIME)?_c('product-lead-time',{attrs:{"time":_vm.product.productInfos.LEAD_TIME,"showTooltip":true}}):_vm._e(),(_vm.product.available > 0)?_c('ProductQty',{key:_vm.key,attrs:{"selectedOptions":_vm.selectedOptions,"product":_vm.product,"item":_vm.item,"position":_vm.position}}):_c('div',{staticClass:"product-not-available"},[(
          (_vm.cart.shippingAddress.deliveryServiceId === 3 ||
            _vm.cart.shippingAddress.deliveryServiceId === 7 ||
            _vm.cart.shippingAddress.deliveryServiceId === 11) &&
            _vm.product.productInfos &&
            _vm.product.productInfos.LOCKER_LEVEL
        )?_c('span',[_vm._v(_vm._s(_vm.$t("product.notAvailableForLocker")))]):(_vm.product.dayLock)?_c('span',[_vm._v(_vm._s(_vm.$t("product.dayLock")))]):_c('span',[_vm._v(_vm._s(_vm.$t("product.notAvailable")))])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }